import Vue from "vue"
import VueRouter from "vue-router"
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

/**
 * 加载模块路由
 */
const modulesFiles = require.context("./modules", true, /\.js$/)
// 自动引入modules目录下的所有模块
const routesModules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  modules.push(value.default)
  return modules
}, [])

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      Menu: false
    },
    component: () => import("@/views/login.vue")
  },
  {
    path: "/",
    name: "home",
    meta: {
      keepAlive: true,
      noNeedLogin: true,
      title: "首页"
    },
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        name: "welcome",
        meta: {
          isMenu: false,
          title: "首页",
          icon: "el-icon-s-home",
          type: "0"
        },
        component: () => import("../views/welcome.vue")
      },
      ...routesModules
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
