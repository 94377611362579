import Vue from "vue"
import axios from "axios"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import ElementUI from "element-ui"
import VueCookies from "vue-cookies"
import "element-ui/lib/theme-chalk/index.css"

axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = store.state.token
  // 必须返回config
  return config
})
axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 200) {
      // ElementUI.Message.success(response.data.msg)
    } else if (response.data.code === 401) {
      ElementUI.Message.warning(response.data.message)
      store.commit("setToken", null)
      store.commit("setUserInfo", null)
      router.push("/login")
    } else if (response.data.code === 500) {
      ElementUI.Message.error(response.data.message)
    }
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(ElementUI)
Vue.use(VueCookies)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
