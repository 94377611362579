export default {
  path: "tripartite-management",
  name: "tripartite-management",
  meta: {
    title: "三方管理",
    icon: "el-icon-s-data"
  },
  component: () => import("@/views/tripartite-management/index.vue"),
  redirect: "/tripartite-management/platform-management",
  children: [
    {
      path: "/tripartite-management/platform-management",
      name: "platform-management",
      meta: {
        title: "平台管理",
        icon: "el-icon-s-grid"
      },
      component: () => import("@/views/tripartite-management/platform-management/index.vue")
    },
    {
      path: "/tripartite-management/account-management",
      name: "account-management",
      meta: {
        title: "账号管理",
        icon: "el-icon-s-data"
      },
      component: () => import("@/views/tripartite-management/account-management/index.vue")
    },
    {
      path: "/tripartite-management/application-record",
      name: "application-record",
      meta: {
        title: "申请管理",
        icon: "el-icon-s-marketing"
      },
      component: () => import("@/views/tripartite-management/application-record/index.vue")
    }
  ]
}
