import Vue from "vue"
import Vuex from "vuex"
import CreatePersistedstate from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    CreatePersistedstate({
      storage: window.sessionStorage
    })
  ],
  state: {
    platformOption: {}, //平台数据
    opcoNameOption: [], //子公司
    token: null, // 用户toekn
    userInfo: null // 用户信息
  },
  getters: {},
  mutations: {
    setToken(store, token) {
      store.token = token
    },
    setUserInfo(store, userInfo) {
      store.userInfo = userInfo
    }
  },
  actions: {
    async getPlatformOption(Store, that) {
      let res = await that.$http.get("/apis/tripartite/getPlatform")
      if (res.code !== 200) return
      Store.state.platformOption = res.data
    },
    async getDict(Store, that) {
      let res = await that.$http.get("/apis/system/getDict")
      if (res.code !== 200) return
      Store.state.opcoNameOption = res.data.find(
        (item) => (item.name = "opcoName")
      )?.value
    }
  },
  modules: {}
})
