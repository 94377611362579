export default {
  path: "/system-setting",
  name: "system-setting",
  meta: {
    title: "系统设置",
    icon: "el-icon-setting"
  },
  component: () => import("@/views/system-setting/index.vue"),
  redirect: "/system-setting/account-setting",
  children: [
    {
      path: "/system-setting/account-setting",
      name: "account-setting",
      meta: {
        title: "账号设置",
        icon: "el-icon-s-grid"
      },
      component: () => import("@/views/system-setting/account-setting/index.vue")
    },
    {
      path: "/system-setting/dict-setting",
      name: "dict-setting",
      meta: {
        title: "字典设置",
        icon: "el-icon-s-grid"
      },
      component: () => import("@/views/system-setting/dict-setting/index.vue")
    }
  ]
}
